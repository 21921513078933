<template>
  <div id="objection-modal-container-202101231128"
       v-if="!!selectedObjection"
  >
    <b-modal id="objection-modal-202101231128"
             :body-class="['modal-body--custom', { 'border-radius-16' : objectionLoading }]"
             footer-class="justify-content-between"
             centered
             v-model="displayShortcutModal"
             :title="staticText.shortcutTitle"
             :hide-footer="objectionLoading"
             size="lg"
             no-close-on-backdrop
             no-close-on-esc
             @close="closeClicked"
    >
      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>
      <template v-slot:modal-title>
        <div
          v-if="!(selectedObjectionLength > 1)"
          class="d-flex align-items-center"
        >
          <img src="../../../../public/img/icons/objections-icon.svg" class="mr-2"/>
          {{ objectionChildren[0].name }}
        </div>
        <div v-else>
          <bao-editable-text
            :value="selectedObjection.name"
            id="bao-editable-text-202304060901"
            :show-pen-icon="true"
            :max-character-limit="120"
            :max-character-limit-message="staticText.shortcutNameMaxCharacterLimitMessage"
            @input-changed="changeShortcutTitle"
          ></bao-editable-text>
          <b-tooltip
            :title="staticText.changeShortcutTitleTooltip"
            target="bao-editable-text-202304060901"
            :disabled="!showShortcutTitleTooltip"
            placement="bottom"
            :show.sync="showShortcutTitleTooltip"
          ></b-tooltip>
        </div>
      </template>

      <div>
        <!-- Error display -->
        <div v-if="showObjectionError">
          <b-alert :show="!!error"
                   variant="danger"
                   :dismissible="!!selectedObjection.workflow.id"
                   @dismissed="resetGlobalError"
          >
            <span v-html="error"></span>
          </b-alert>
        </div>

        <!-- Objection Loading -->
        <div v-if="showObjectionLoading">
          <progress-indicator :loading-label="savingObjection ? staticText.saving : null"></progress-indicator>
        </div>

        <!-- Content - objections-items-container -->
        <div v-if="objectionLoadingCompleted"
             class="mx-auto"
        >
          <!-- Shortcut Items  display -->
          <playbook-configurator-item
            v-if="selectedObjection.workflow && mainContainer"
            :selected-objection="selectedObjection"
            :main-container="selectedObjection.workflow"
            :playbook-items="selectedObjection.workflow.children"
            :item-types="playbookItemTypes(true)"
            :isOpenFromShortcutsModal="true"
            :can-modify="canModify"
            @items-changed="handleItemsChanged"
            @item-changed="handleItemChanged"
            @shortcut-deleted="handleShortcutDeleted"
            @delete-shortcut-item="handleDeleteShortcut"
            @error="handleError"
            @add-item-before-current="onAddItemBeforeCurrent"
            @add-library-item-before-current="onAddLibraryItemBeforeCurrent"
            @handle-version-changed="handleVersionChanged"
          ></playbook-configurator-item>

          <!-- Create/Link item buttons -->
          <new-item-controls
            v-if="canModify"
            :playbook-items="objectionChildren"
            :main-container-id="selectedObjection.workflow.id"
            @create="addNewPlaybookItemToObjection(selectedObjection)"
            @link="linkLibraryItemToObjection"
          >
          </new-item-controls>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <!-- Delete Button -->
          <bao-delete-button
            v-if="canModify"
            :id="'shortcut-btn-remove-' + selectedObjection.id"
            :url="getDeleteShortcutUrl"
            extra-class="mr-2"
            :label="staticText.deleteLabel"
            :hide-icon="true"
            @delete="deleteUnsavedShortcut"
            @deleted="handleShortcutDeleted"
          >
            {{ staticText.shortcutDeleteWarning }}
          </bao-delete-button>
          <button
            class="btn btn-primary"
            :disabled="!canModify"
            @click="handleSaveAndCloseObjectionModal"
          >
            {{ staticText.objectionSaveAndCloseBtn }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { playbookServices } from "../PlaybookServices"
import PlaybookConfiguratorItem from "../PlaybookConfiguratorItem"
import NewItemControls from "@/apps/talkscript/components/NewItemControls"
import BaoEditableText from "@/apps/base/BaoEditableText"
import ProgressIndicator from "../../base/ProgressIndicator"
import PlaybookItemMixin from "../PlaybookItemMixin"
import BaoDeleteButton from "@/apps/base/BaoDeleteButton"
import axios from "axios"
import appInfo from "@/apps/talkscript/index"

export default {
  name: "ShortcutsModal",
  mixins: [PlaybookItemMixin],
  data () {
    return {
      selectedObjection: null,
      clonedSelectedObjection: null,
      objectionParent: null,
      showShortcutTitleTooltip: false,
      savingObjection: false,
      error: null,
      displayShortcutModal: false,
      itemsToDelete: [],
      staticTextDefault: {
        shortcutTitle: "Shortcut",
        saving: "Saving ...",
        shortcutNameMaxCharacterLimitMessage: "The shortcut name cannot exceed 120 characters.",
        changeShortcutTitleTooltip: "You can now give the shortcut a more specific title, as you added more than one item to it.",
        objectionSaveAndCloseBtn: "Save & Close",
        shortcut: "Shortcut",
        itemHasNoNameErr: " has an invalid name",
        deleteLabel: "Delete",
        shortcutDeleteWarning: "Are you sure you want to delete this shortcut?"
      }
    }
  },
  components: {
    PlaybookConfiguratorItem,
    NewItemControls,
    BaoEditableText,
    ProgressIndicator,
    BaoDeleteButton
  },
  props: {
    canModify: {
      type: Boolean,
      default: true,
      required: false
    },
    mainContainer: {
      type: Object,
      default: () => {}
    },
    parentItemIndex: {
      type: Number,
      default: 0,
      required: false
    },
    shortcutsOnItem: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  watch: {
    selectedObjectionLength (newLength, oldLength) {
      if (oldLength === 1 && newLength === 2) this.toggleShortcutTooltip()
      if (oldLength === 2 && newLength === 1) this.changeShortcutTitle(this.objectionChildren[0].name)
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    baseUrl () {
      return appInfo.apiUrl.playbookItem
    },
    showObjectionError () {
      return !!this.error
    },
    objectionLoading () {
      return this.savingObjection
    },
    objectionLoadingCompleted () {
      return !this.savingObjection
    },
    showObjectionLoading () {
      return this.objectionLoading && !this.showObjectionError
    },
    selectedObjectionLength () {
      if (this.selectedObjection && this.objectionChildren) {
        return this.objectionChildren.length
      }
      return 0
    },
    getDeleteShortcutUrl () {
      return this.selectedObjection.id ? `/api/objectionchoices/${this.selectedObjection.id}` : null
    },
    objectionChildren () {
      return this.selectedObjection ? this.selectedObjection.workflow.children : []
    }
  },
  methods: {
    ...playbookServices.methods,
    changeShortcutTitle (title) {
      this.selectedObjection.name = title
    },
    toggleShortcutTooltip () {
      this.showShortcutTitleTooltip = true
      setTimeout(() => {
        this.showShortcutTitleTooltip = false
      }, 7000)
    },
    closeClicked () {
      if (this.clonedSelectedObjection.id) this.objectionParent.objections.splice(this.clonedSelectedObjection.order - 1, 1, this.clonedSelectedObjection)
      this.closeShortcutModal()
    },
    handleSaveAndCloseObjectionModal (event) {
      event.preventDefault()
      this.saveAndCloseObjectionModal().catch(error => {
        console.debug(error)
      })
    },
    deleteUnsavedShortcut () {
      if (!this.selectedObjection.id) this.closeShortcutModal()
    },
    handleDeleteShortcut (index) {
      if (this.selectedObjection.id) {
        const itemToDelete = this.objectionChildren[index]
        if (itemToDelete.id) this.itemsToDelete.push(itemToDelete)
      }
      this.removeItemFromList(index)
    },
    handleShortcutDeleted () {
      this.$emit("shortcut-deleted", this.selectedObjection)
      this.closeShortcutModal()
    },
    closeShortcutModal () {
      this.displayShortcutModal = false
      this.selectedObjection = null
      this.savingObjection = false
    },
    async saveAndCloseObjectionModal () {
      return new Promise((resolve, reject) => {
        // To avoid closing the modal when user clicks on the button
        const that = this
        that.savingObjection = true
        const error = that.validateObjection(that.selectedObjection)
        if (error) {
          that.savingObjection = false
          reject(error)
          return
        }

        const savingPromise = !this.selectedObjection.id ? that.handleSavingNewObjection() : that.handleUpdatingObjection()

        resolve(savingPromise)
      })
    },
    handleSavingNewObjection () {
      this.createObjection(this.selectedObjection).then((savedObjection) => {
        this.objectionParent.objections.splice(savedObjection.order - 1, 0, savedObjection)
        this.closeShortcutModal()
      }).catch(error => {
        this.savingObjection = false
        this.showError(this.extractErrorMessage(error))
      })
    },
    async handleUpdatingObjection () {
      const savingPromises = []
      // This array is important so we store the saved shortcuts on the list. New shortcuts would not have an id
      // and this makes it difficult to update the order of the items correctly. But by tracking the indexes and
      // updating the saved item, we can get the new id afterwards, and then, update the order
      const indexesToBeUpdated = []

      // Handle saving/updating of shortcut items
      for await (const [index, shortcut] of this.selectedObjection.workflow.children.entries()) {
        shortcut.order = index + 1
        if (shortcut.awaitingSaving || !shortcut.id || !!shortcut.awaitingVersionUpdateId) this.handleShortcutSaving(shortcut, index, savingPromises, indexesToBeUpdated)
      }

      // Handle deletion of shortcuts
      for (const shortcutItem of this.itemsToDelete) savingPromises.push(this.deleteShortcutItem(shortcutItem))

      await Promise.all(savingPromises).then(async (responses) => {
        // TODO: Handle individual errors here more appropriately
        await this.updateSavedShortcuts(responses, indexesToBeUpdated)

        // TODO: Only update objection in list if there's a change in name
        await this.updateObjectionInList(this.selectedObjection)

        this.handleOrderChanged(this.selectedObjection.workflow, this.objectionChildren, 0)
        this.closeShortcutModal()
      }).catch(error => {
        this.savingObjection = false
        this.showError(this.extractErrorMessage(error))
      })
    },
    handleShortcutSaving (shortcut, index, savingPromises, indexesToBeUpdated) {
      if (shortcut.is_library_item) savingPromises.push(this.linkPlaybookItem(shortcut, this.selectedObjection.workflow, index))
      else {
        if (shortcut.awaitingSaving) savingPromises.push(this.savePlaybookItem(shortcut))
        else {
          const { id, awaitingVersionUpdateId } = { ...shortcut }
          savingPromises.push(this.updateItemVersion(id, awaitingVersionUpdateId))
        }
        // The indexes of each objection that's saved is added to the array. The shortcutIndex is the index on the objection's children
        // list. the Saving index is the index on the promises Array
        // The returned value is extracted from the promises Array, and updated on the objection's children list
        indexesToBeUpdated.push({
          shortcutIndex: index,
          savingIndex: savingPromises.length - 1
        })
      }
    },
    async updateSavedShortcuts (responses, indexesToBeUpdated) {
      // For every objection item that is saved/updated, we update the saved item on the objections list
      // This is very important especially to save the order of items on the list, as the ID is required
      for await (const indexes of indexesToBeUpdated) {
        const response = responses[indexes.savingIndex]
        if (response && response.data && response.data.id) {
          const updatedItem = response.data
          this.replaceItemInList(updatedItem, indexes.shortcutIndex)
        }
      }
    },
    deleteShortcutItem (item) {
      try {
        const deleteUrl = item.is_library_item ? `${this.baseUrl}/${this.selectedObjection.workflow.id}/de_link_child?child=${item.id}` : `${this.baseUrl}/${item.id}`

        axios.delete(deleteUrl)
      } catch (error) {
        console.error(error)
      }
    },
    updateItemVersion (itemId, versionId) {
      try {
        axios.put(`${this.baseUrl}/${itemId}/update_to_version?version=${versionId}`)
      } catch (error) {
        console.error(error)
      }
    },
    async handleVersionChanged ({ item, versionId }) {
      // This gets the item version, rather than update it. If user clicks 'save and close', then the version is updated
      // before closing the modal
      const { data } = await axios.get(`${this.baseUrl}/${item.id}/get_item_matching_version?version=${versionId}`)

      // Since this is just a clone of the version, some important keys from the original item must remain; so only the data from
      // the old version is used. A key would also be added to let us know which items we need to update the version when the user
      // saves, and the version we need to update to.
      const { id, uniqueId, parents, versions, total_active_parents, is_objection } = { ...item }
      const versionClone = { ...data, id, uniqueId, parents, versions, total_active_parents, is_objection }
      const itemIndex = this.findItemIndex(this.objectionChildren, item)

      const newItem = { ...versionClone, awaitingSaving: false, awaitingVersionUpdateId: data.id }
      this.replaceItemInList(newItem, itemIndex)
    },
    validateObjection (objection) {
      if (!objection.name) {
        this.showError(this.staticText.shortcutTitle + this.staticText.itemHasNoNameErr)
        return true
      } else if (this.validateShortcutItems(objection.workflow.children)) {
        return true
      }
      return false
    },
    validateShortcutItems (itemArray) {
      const errorPositions = this.validatePlaybookChildren(itemArray)
      this.resetGlobalError()
      let globalErrorMsg = null
      if (errorPositions.length > 0) {
        globalErrorMsg = this.getGlobalErrorMsg(errorPositions, true)
        this.showError(globalErrorMsg)
      }
      return globalErrorMsg
    },
    updateObjectionInList (objection) {
      /**
       * This method updates the objection in the objection list of the item(parent of the objection)
       * The objection name is updated as its first child(playbook-item) name
       **/
      return new Promise((resolve, reject) => {
        // Note the below error must never happen. If this error occurs its an implementation fault
        if (!objection.talkscript) {
          const errorStr = "Objection: " + objection.name + " at position " + objection.order + " has no playbook"
          this.showError(errorStr)
          reject(errorStr)
          return
        }
        return this.saveShortcut(objection, this.objectionParent.id).then(() => {
          this.objectionParent.objections.splice(objection.order - 1, 1, objection)
          resolve()
        }).catch(error => {
          this.showError(this.extractErrorMessage(error))
          reject(error)
        })
      })
    },
    saveShortcut (objection, objectionParentId) {
      return new Promise((resolve, reject) => {
        const objectionPlaybook = {
          id: objection.talkscript,
          name: objection.name,
          type: "objection"
        }
        return this.savePlaybook(objectionPlaybook).then(() => {
          return this.saveObjectionChoice(objection, objectionParentId).then(() => {
            resolve()
          })
        }).catch(error => {
          reject(error)
        })
      })
    },
    showError (error) {
      this.error = error
    },
    resetGlobalError () {
      this.error = null
    },
    objectionWasSelected ({ objection, mainContainer }, resetError = true) {
      // This method opens the objection in a modal.
      if (resetError) this.resetGlobalError()
      this.selectedObjection = objection
      this.clonedSelectedObjection = JSON.parse(JSON.stringify(objection))
      this.objectionParent = mainContainer
      this.displayShortcutModal = true
    },
    createObjection (objection) {
      /**
       * itemPosition is the position of the item in the playbook where the objection needs to be added. (The parent of the objection)
       * If the itemPosition is null, then the parent is considered to be the mainContainer. Based on the
       * type of parent (mainContainer or a playbookItem) the item is updated.
       **/
      const itemPosition = this.shortcutsOnItem ? this.parentItemIndex + 1 : null

      const that = this
      return new Promise((resolve, reject) => {
        that.saveObjection(objection, that.objectionParent.id).then(response => {
          // If the item is mainContainer we don't need to update item as objection creation already adds it to the item
          // but we need to update the order of objection in the mainContainer' objection list.
          // If item is not mainContainer, we still need to create a new version of the playbook item with newly created
          // objection to keep track of all the objections added to the item, so we update the playbook item (parent)
          let itemUpdatePromise = null
          if (!itemPosition) {
            itemUpdatePromise = that.orderWasChanged(0)
          } else {
            itemUpdatePromise = that.handleSavePlaybookItem(that.objectionParent, 0)
          }
          that.$emit("update-last-saved")
          itemUpdatePromise.then(() => {
            resolve(response.data)
          })
        }).catch(error => {
          that.showError(that.extractErrorMessage(error))
          reject(error)
        })
      })
    },
    orderWasChanged (delayTime) {
      return this.handleOrderChanged(this.mainContainer, this.mainContainer.children, delayTime).then(
        this.$emit("update-last-saved")
      ).catch(error => {
        this.showError(this.extractErrorMessage(error))
      })
    },
    handleError (errorData, isObjection = false) {
      const errorMsg = this.extractErrorMessage(errorData.message)
      if (errorData.item && !errorMsg) {
        // If error is null and item is passed then reset error on global and item-level
        this.resetItemError(errorData.item)
        this.resetGlobalError()
      } else if (errorData.item && errorMsg) {
        // If error exists and item is passed then set error on global-level(with link to erroneous-item) and item-level
        this.setItemError(errorData.item, errorMsg)
        this.setGlobalError(errorData.item, errorData.position)
      } else {
        // If item is not passed then set error on global-level as the error message passed
        this.showError(errorMsg, isObjection)
      }
    },
    addNewPlaybookItemToObjection (objection) {
      if (!objection.workflow.children) this.$set(objection.workflow, "children", [])
      this.addNewPlaybookItemAtEnd(objection.workflow, objection.workflow.children, true)
    },
    onAddItemBeforeCurrent (newItemIndex) {
      const newItem = this.getNewObjectionItem(newItemIndex)
      this.addNewItemToList(newItem, newItemIndex)
    },
    onAddLibraryItemBeforeCurrent (selectedLibraryItem, newItemIndex) {
      const newItem = { ...selectedLibraryItem, awaitingSaving: true, order: newItemIndex + 1 }
      this.addNewItemToList(newItem, newItemIndex)
    },
    addNewPlaybookItemAtEnd () {
      const newIndex = this.objectionChildren.length
      const newItem = this.getNewObjectionItem(newIndex)
      this.addNewItemToList(newItem, newIndex)
    },
    addNewItemToList (item, index) {
      this.selectedObjection.workflow.children.splice(index, 0, item)
    },
    replaceItemInList (newItem, index) {
      this.selectedObjection.workflow.children.splice(index, 1, newItem)
    },
    removeItemFromList (index) {
      this.selectedObjection.workflow.children.splice(index, 1)
    },
    getNewObjectionItem (newItemIndex) {
      const newItemConfig = { isObjection: true, order: newItemIndex + 1 }
      if (this.selectedObjection.workflow.id) newItemConfig.parent = this.selectedObjection.workflow.id
      return this.getDefaultPlaybookItem(newItemConfig)
    },
    handleItemsChanged (items) {
      this.selectedObjection.workflow.children = items
    },
    handleItemChanged (item, itemPosition) {
      if (item && itemPosition === 1 && !(this.selectedObjectionLength > 1)) this.changeShortcutTitle(item.name)

      const newItem = { ...item, awaitingSaving: true, awaitingVersionUpdateId: null }
      this.replaceItemInList(newItem, itemPosition - 1)
    },
    setGlobalError (item, itemPosition) {
      const errorDetails = [{ position: itemPosition, itemLink: item.uniqueId }]
      const errorMsg = this.getGlobalErrorMsg(errorDetails, item.is_objection)
      this.showError(errorMsg)
    },
    linkLibraryItemToObjection (selectedLibraryItem) {
      const itemIndex = this.objectionChildren.length
      const newItem = { ...selectedLibraryItem, awaitingSaving: true, order: itemIndex + 1 }
      this.addNewItemToList(newItem, itemIndex)
    }
  }
}
</script>

<style scoped lang="scss">

:deep(.modal-body--custom) {
  background: $background;
  &.border-radius-16 {
    border-radius: 0 0 16px 16px;
  }
}

</style>
